var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "flex flex-col space-y-2 lg:space-y-3 text-left" },
    [
      _vm._l(_vm.items, function (item, index) {
        return _c("li", { key: index }, [
          _c(
            "label",
            {
              staticClass: "answer-item",
              class:
                _vm.value.value === item.value
                  ? "border-black"
                  : "border-transparent",
              attrs: { for: item.value },
            },
            [
              _c("input", {
                staticClass: "answer-item__radio",
                attrs: { id: item.value, type: "radio", name: _vm.name },
                domProps: {
                  value: _vm.value.value,
                  checked: _vm.value.value === item.value,
                },
                on: {
                  change: function ($event) {
                    return _vm.$emit("handle-change", item)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "answer-item__indicator" }, [
                _c(
                  "svg",
                  {
                    staticClass: "h-4 transition-opacity duration-200 p-px",
                    class:
                      _vm.value.value === item.value
                        ? "opacity-100"
                        : "opacity-0",
                    attrs: { viewBox: "0 0 18 15" },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M17.7434 1.66954L6.18368 14.4936L0.257812 7.91954L1.74336 6.58046L6.18368 11.5064L16.2578 0.33046L17.7434 1.66954Z",
                        fill: "white",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("span", { domProps: { textContent: _vm._s(item.text) } }),
            ]
          ),
        ])
      }),
      _vm._v(" "),
      _c("li", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.note,
            expression: "note",
          },
        ],
        staticClass: "text-left text-base pt-4",
        domProps: { textContent: _vm._s(_vm.note) },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }