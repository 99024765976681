<template>
  <form
    class="grid lg:grid-cols-2 gap-y-12 lg:gap-y-6 gap-x-10 max-w-5xl mx-auto"
    @submit="submit"
  >
    <div class="lg:col-span-1 space-y-7">
      <div>
        <label
          class="block text-sm lg:text-lg ml-2px mb-1 lg:ml-2 lg:mb-2"
          for="name"
          >Full Name*</label
        >
        <input
          id="name"
          v-model="name"
          class="appearance-none rounded-xl w-full py-4 px-5 text-gray-700 leading-tight lg:text-xl focus:outline-none border"
          :class="
            formInvalid && $v.name.$invalid ? 'border-red' : 'border-white'
          "
          type="text"
          placeholder="Bianca Imogen"
        />
      </div>
      <div>
        <label
          class="block text-sm lg:text-lg ml-2px mb-1 lg:ml-2 lg:mb-2"
          for="colour"
          >Favourite Natural Paint Co. colour?</label
        >
        <input
          id="colour"
          v-model="colour"
          class="appearance-none rounded-xl w-full py-4 px-5 text-gray-700 leading-tight lg:text-xl focus:outline-none border border-white"
          type="text"
          placeholder="Wild Thyme"
        />
      </div>
    </div>
    <div class="lg:col-span-1 flex flex-col">
      <label
        class="block text-sm lg:text-lg ml-2px mb-1 lg:ml-2 lg:mb-2"
        for="image"
        >Upload image</label
      >
      <div
        class="border border-white rounded-lg h-full flex flex-col justify-between items-center p-3"
      >
        <div
          class="flex-1 flex flex-col justify-center items-center text-center gap-y-3 py-6 lg:py-0"
        >
          <div class="relative mt-6">
            <label
              for="file"
              class="btn-pill btn-pill--strong-alt hover:bg-black hover:border-black hover:text-white inline-flex space-x-2 w-56"
            >
              <span class="w-6 h-6">
                <IconAddAlt />
              </span>
              <span>Add file</span>
              <input
                id="file"
                type="file"
                class="hidden"
                accept="image/png, image/jpeg"
                @change="handleFileUpload($event)"
              />
            </label>
            <div
              v-if="this.file"
              class="text-xs absolute top-full inset-x-0 mt-2"
              v-text="this.file.name"
            ></div>
          </div>
        </div>
        <div class="text-sm pt-3">Supports JPG/PNG</div>
      </div>
    </div>
    <div class="lg:col-span-2 pl-2px lg:pl-2">
      <a
        class="link underline text-sm"
        target="_blank"
        href="/unblur-your-background-terms-conditions"
        >Read our Terms &amp; Conditions</a
      >
    </div>
    <div class="lg:col-span-2 text-center lg:pt-12 lg:-mb-10">
      <button
        class="btn-pill btn-pill--strong text-sm md:text-base min-h-10 py-2 px-6 w-56"
        type="submit"
        :disabled="submitting"
        :class="submitting ? ' opacity-50' : ''"
      >
        Submit
      </button>
      <div class="h-6 mt-4">
        <p v-if="successMessage">{{ successMessage }}</p>
        <p v-if="errorMessage" class="text-red">{{ errorMessage }}</p>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import IconAddAlt from "../../../templates/_includes/svg/icon-add-alt.svg";
import { flattenUnblurYourBackgroundAnswers } from "../../js/utils";

export default {
  name: "UnblurYourBackgroundResultsForm",
  components: {
    IconAddAlt,
  },
  mixins: [validationMixin],
  props: ["result"],
  data() {
    return {
      submitting: false,
      successMessage: "",
      errorMessage: "",
      formInvalid: false,
      file: "",
    };
  },
  computed: {
    name: {
      get() {
        return this.$store.state.quiz.details.name;
      },
      set(value) {
        this.$store.commit("setName", value);
      },
    },
    email: {
      get() {
        return this.$store.state.quiz.details.email;
      },
    },
    colour: {
      get() {
        return this.$store.state.quiz.details.colour;
      },
      set(value) {
        this.$store.commit("setColour", value);
      },
    },
  },
  validations: {
    name: {
      required,
    },
  },
  methods: {
    handleFileUpload(e) {
      const [value] = e.target.files;
      this.file = value;
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject();
        }

        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async submit(e) {
      e.preventDefault();

      if (this.$v.$invalid) {
        this.formInvalid = true;
        this.errorMessage = "Please enter form fields";
      } else {
        this.formInvalid = false;
        this.submitting = true;
        this.successMessage = "";
        this.errorMessage = "";

        // Format names for hubspot.
        const names = this.name.split(" ");
        const firstname = names[0];
        const lastname = names[1] || "";

        // Flatten this.$store.state.quiz.questions object.
        const answers = Object.entries(this.$store.state.quiz.questions).reduce(
          flattenUnblurYourBackgroundAnswers,
          {}
        );

        // Parse file to base64.
        let file;
        if (this.file) {
          file = await this.readFile(this.file);
        }

        const body = {
          firstname,
          lastname,
          email: this.email,
          file,
          campaign_unblur_your_background_result: this.result || "",
          campaign_unblur_your_background_colour: this.colour || "",
          ...answers,
        };

        axios
          .post(
            "https://4lnoky9ks8.execute-api.ap-southeast-2.amazonaws.com/prod/create-contact",
            body,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.data.status === "error") {
              this.errorMessage = res.data.message;
            } else {
              this.successMessage = "Got to be in it to win it! Good luck.";
            }
          })
          .catch(() => {
            this.errorMessage = "Something went wrong, please try again.";
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
  },
};
</script>
