<template>
  <div class="quiz-wrapper">
    <div class="pt-4 pb-8 lg:py-30 js-router-scroll-target">
      <div class="text-xs font-semibold lg:hidden">
        Colour: {{ colour.name }}
      </div>
      <div class="pt-15 lg:pt-0 px-ogs">
        <h3 class="text-4xl lg:text-6xl mb-2 leading-tight uppercase">
          {{ currentQuestion }}/18
        </h3>
        <h4
          class="max-w-sm mx-auto lg:max-w-2xl text-2xl xl:text-3xl xl:max-w-3xl leading-tight mb-8 lg:mb-15"
          v-html="question"
        ></h4>

        <div class="lg:grid lg:grid-cols-12">
          <div class="hidden lg:block relative">
            <div
              class="lg:absolute lg:inset-0 flex items-center justify-center"
            >
              <div
                class="font-semibold lg:transform lg:-rotate-90 whitespace-no-wrap"
              >
                Colour: {{ colour.name }}
              </div>
            </div>
          </div>
          <div class="col-span-10">
            <div class="w-full xl:max-w-6xl xl:px-5 lg:mx-auto">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>

      <UnblurYourBackgroundNavigation
        :theme="theme"
      ></UnblurYourBackgroundNavigation>
    </div>
  </div>
</template>

<script>
import UnblurYourBackgroundNavigation from "./UnblurYourBackgroundNavigation.vue";

export default {
  name: "UnblurYourBackgroundQuizWrapper",
  components: {
    UnblurYourBackgroundNavigation,
  },
  props: {
    question: {
      type: String,
      required: true,
    },
    colour: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentQuestion() {
      return this.$store.state.quiz.currentQuestion;
    },
  },
};
</script>

<style lang="css" scoped>
.quiz-wrapper {
  @apply text-center transition duration-300;
  background-color: var(--quiz-paint);
  color: var(--quiz-theme);
}
</style>
