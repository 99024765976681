<template>
  <div class="relative z-10 max-w-lg md:max-w-7xl mx-auto">
    <div
      v-if="!loading && grid.length"
      class="grid md:grid-cols-4 gap-2 lg:gap-6 xl:gap-10 text-center"
    >
      <div
        v-for="item in grid"
        :key="item.id"
        class="rounded-lg overflow-hidden"
        :class="widthClass(item.width)"
      >
        <div
          v-if="item.typeHandle === 'text'"
          class="bg-white h-full flex items-center justify-center w-full"
          :class="textRatioClass(item.width)"
        >
          <div
            class="p-10 box-content max-w-lg mx-auto lg:text-md xl:text-xl"
            v-html="item.text"
          ></div>
        </div>
        <div
          v-else-if="item.typeHandle === 'image'"
          class="overflow-hidden h-full"
          :class="ratioClass(item.width)"
        >
          <picture>
            <source type="image/webp" :srcSet="item.image[0].webp" />
            <img
              class="w-full h-full object-cover"
              loading="lazy"
              :src="item.image[0].url"
              :alt="item.image[0].title"
            />
          </picture>
        </div>
        <a
          v-else
          class="h-full px-6 py-10 lg:p-4 flex items-center justify-center transition-opacity duration-200 hover:opacity-70 w-full"
          :href="`/colours?colour=${item.colour[0].slug}`"
          target="_blank"
          :style="{ backgroundColor: item.colour[0].colour }"
          :class="[
            textClass(item.colour[0].textColour),
            colourRatioClass(item.width),
          ]"
        >
          <div>
            <div
              class="text-xxs uppercase mb-1 tracking-wide font-semibold leading-tight"
              v-text="item.heading"
            ></div>
            <h5
              class="text-3xl md:text-xl lg:text-3xl xl:text-4xl leading-tight"
              v-text="item.colour[0].title"
            ></h5>
          </div>
        </a>
      </div>
    </div>
    <div
      v-else
      class="grid md:grid-cols-4 gap-2 lg::gap-6 xl:gap-10 text-center"
    >
      <div
        v-for="i in 9"
        :key="i"
        class="rounded-lg bg-white animate-pulse h-full w-full"
        :class="skeletonClass(i)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnblurYourBackgroundResultsGrid",
  props: {
    grid: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    skeletonClass(value) {
      if (value === 1 || value === 5 || value === 9) {
        return "md:col-span-2 ratio-half";
      }
      return "md:col-span-1 ratio-quarter";
    },
    ratioClass(value) {
      if (value === "1/2") {
        return "ratio-half";
      }
      return "ratio-quarter";
    },
    textRatioClass(value) {
      if (value === "1/2") {
        return "text-ratio-half";
      }
      return "text-ratio-quarter";
    },
    colourRatioClass(value) {
      if (value === "1/2") {
        return "ratio-colour-mobile lg:ratio-half";
      }
      return "ratio-colour-mobile lg:ratio-quarter";
    },
    widthClass(value) {
      if (value === "1/2") {
        return "md:col-span-2";
      }
      return "md:col-span-1";
    },
    textClass(value) {
      if (value === "black") {
        return "text-black";
      }
      return "text-white";
    },
  },
};
</script>

<style lang="css" scoped>
@responsive {
  .ratio-half {
    aspect-ratio: 8 / 5.2;
  }

  .ratio-quarter {
    aspect-ratio: 6 / 7;
  }

  .ratio-colour-mobile {
    aspect-ratio: 2 / 1;
  }
}

@screen md {
  .text-ratio-half {
    aspect-ratio: 8 / 5.2;
  }
  .text-ratio-quarter {
    aspect-ratio: 6 / 7;
  }
  .ratio-quarter {
    aspect-ratio: 5 / 7;
  }
}
</style>
