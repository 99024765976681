const flattenUnblurYourBackgroundAnswers = (acc, [key, value]) => {
  acc[`campaign_unblur_your_background_answer_${key.toLowerCase()}`] =
    value.text || "";
  return acc;
};

const pluralize = (count, noun, suffix = "s") => {
  if (Number.isNaN(Number(count))) return count;
  return `${count} ${noun}${count !== 1 ? suffix : ""}`;
};

const formatVolume = (amount, unit = "Litre") => {
  if (Number.isNaN(Number(amount))) return amount;
  return pluralize(amount, unit);
};

const removeEmptyElements = (array) =>
  array.filter((el) => {
    if (typeof el === "object") return !!Object.keys(el).length;
    if (typeof el === "string") return !!el.length;
    return true;
  });

const removeSvgFill = (svg) => {
  [svg, ...svg.querySelectorAll("*")].forEach((el) =>
    el.removeAttribute("fill")
  );
  return svg;
};

module.exports = {
  flattenUnblurYourBackgroundAnswers,
  pluralize,
  formatVolume,
  removeEmptyElements,
  removeSvgFill,
};
