export default {
  mounted() {
    this.setTheme();
    this.scrollTop = this.$root.getScrollTop();
    window.addEventListener("scroll", this.handleScroll);
  },
  updated() {
    this.setTheme();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    setTheme() {
      const text = this.data.theme === "black" ? "#000000" : "#ffffff";

      if (this.scrollTop > 200) {
        this.$store.commit("setSiteTheme", [this.data.colour.hex, text]);
      } else {
        this.$store.commit("setSiteTheme", ["transparent", "#000000"]);
      }

      this.$store.commit("setQuizTheme", [this.data.colour.hex, text]);
    },
    handleScroll() {
      this.scrollTop = this.$root.getScrollTop();
      this.setTheme();
    },
  },
};
