<template>
  <UnblurYourBackgroundQuizWrapper
    :key="$route.name"
    :theme="data.theme"
    :colour="data.colour"
    :question="data.question"
  >
    <div class="text-left lg:-mb-10">
      <label
        class="block text-sm lg:text-lg ml-2px mb-1 lg:ml-2 lg:mb-2"
        for="email"
        >Email address*</label
      >
      <input
        id="email"
        v-model="email"
        class="appearance-none rounded-xl w-full py-4 px-5 text-gray-700 leading-tight lg:text-xl focus:outline-none"
        type="text"
        placeholder="biancaimogen@gmail.com"
      />
      <label
        class="inline-flex items-center text-sm mt-2 ml-2px lg:ml-2 leading-snug relative cursor-pointer"
      >
        <input
          v-model="marketing"
          type="checkbox"
          class="opacity-0 absolute h-0 w-0"
        />
        <span
          class="w-4 h-4 border mr-2 transition-colors duration-200 rounded-xs"
          :class="marketing ? 'bg-black' : 'bg-white'"
        >
          <svg
            class="h-full w-full transition-opacity duration-200 p-2px"
            :class="marketing ? 'opacity-100' : 'opacity-0'"
            viewBox="0 0 18 15"
          >
            <path
              d="M17.7434 1.66954L6.18368 14.4936L0.257812 7.91954L1.74336 6.58046L6.18368 11.5064L16.2578 0.33046L17.7434 1.66954Z"
              fill="white"
            />
          </svg>
        </span>
        <span>I agree to receive marketing emails from Natural Paint Co</span>
      </label>
      <div class="lg:h-6 mt-4">
        <div class="email-error text-red hidden">
          Please enter a valid email address
        </div>
      </div>
    </div>
  </UnblurYourBackgroundQuizWrapper>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import themeMixin from "./themeMixin";
import UnblurYourBackgroundQuizWrapper from "../UnblurYourBackgroundQuizWrapper.vue";

export default {
  name: "Email",
  components: {
    UnblurYourBackgroundQuizWrapper,
  },
  mixins: [themeMixin, validationMixin],
  data() {
    return {
      error: false,
      scrollTop: null,
      data: {
        theme: "black",
        colour: {
          name: "Dusk",
          hex: "#E3E7F3",
        },
        question: "What is your email address?",
      },
    };
  },
  computed: {
    email: {
      get() {
        return this.$store.state.quiz.details.email;
      },
      set(value) {
        this.$store.commit("setEmail", value);
      },
    },
    marketing: {
      get() {
        return this.$store.state.quiz.details.marketing;
      },
      set(value) {
        this.$store.commit("setMarketing", value);
      },
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  mounted() {
    this.checkCanProceed();
  },
  updated() {
    this.checkCanProceed();
  },
  methods: {
    checkCanProceed() {
      const canProceed = !!this.email;
      const validEmail = !this.$v.$invalid;

      this.$store.commit("setCanProceed", { canProceed });
      this.$store.commit("setValidEmail", { validEmail });
    },
  },
};
</script>
