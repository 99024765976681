var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        staticClass:
          "w-full border-b flex items-center justify-between pb-4 mt-4 text-left focus:outline-none group gap-x-6 lg:text-md xl:text-xl",
        on: {
          click: function ($event) {
            _vm.showOptions = !_vm.showOptions
          },
        },
      },
      [
        !_vm.value.text
          ? _c(
              "span",
              {
                staticClass:
                  "opacity-40 group-hover:opacity-70 transition-opacity duration-200",
              },
              [_vm._v("Select an option")]
            )
          : _c("span", [_vm._v(_vm._s(_vm.value.text))]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "w-6 h-3 transform transition-transform duration-200",
            class: { "-rotate-180": _vm.showOptions },
          },
          [_c("IconChevron")],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _vm.showOptions
      ? _c(
          "ul",
          {
            staticClass: "flex flex-col space-y-2 lg:space-y-3 text-left mt-4",
          },
          [
            _vm._l(_vm.items, function (item, index) {
              return _c("li", { key: index }, [
                _c(
                  "label",
                  {
                    staticClass: "answer-item",
                    class:
                      _vm.value.value === item.value
                        ? "border-black"
                        : "border-transparent",
                    attrs: { for: item.value },
                  },
                  [
                    _c("input", {
                      staticClass: "answer-item__radio",
                      attrs: { id: item.value, type: "radio", name: _vm.name },
                      domProps: {
                        value: _vm.value.value,
                        checked: _vm.value.value === item.value,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleChange(item)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", {
                      domProps: { textContent: _vm._s(item.text) },
                    }),
                  ]
                ),
              ])
            }),
            _vm._v(" "),
            _c("li", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.note,
                  expression: "note",
                },
              ],
              staticClass: "text-left text-base pt-4",
              domProps: { textContent: _vm._s(_vm.note) },
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }