var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "absolute top-0 left-0 js-router-scroll-target" }),
    _vm._v(" "),
    _c("div", { staticClass: "bg-paint" }, [
      _c(
        "div",
        { staticClass: "px-ogs", class: { "pb-20": !_vm.showForm } },
        [
          _c(
            "div",
            { staticClass: "max-w-md lg:max-w-4xl mx-auto py-20 text-center" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _c("h1", {
                  staticClass:
                    "text-3xl lg:text-4xl xl:text-5xl mb-4 leading-tight",
                  domProps: { textContent: _vm._s(_vm.heading) },
                }),
              ]),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass: "text-xl lg:text-3xl leading-tight",
                  class: { "mb-10": _vm.showForm },
                },
                [
                  _vm._v(
                    "\n          Unblur your background with these colour options.\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.showForm
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn-pill btn-pill--strong text-sm md:text-base min-h-10 py-2 px-6 w-auto",
                      on: { click: _vm.handleClick },
                    },
                    [_vm._v("\n          Win a paint prize pack!\n        ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("UnblurYourBackgroundResultsGrid", {
            attrs: { loading: _vm.loading, grid: _vm.grid },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showForm
        ? _c(
            "div",
            {
              staticClass:
                "h-24 lg:h-48 bg-gradient-to-t from-wild-thyme -mt-6 lg:-mt-12 flex items-end justify-center",
            },
            [
              _c("div", { staticClass: "text-xs font-semibold lg:hidden" }, [
                _vm._v("Colour: Wild Thyme"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showForm
        ? _c(
            "div",
            {
              staticClass:
                "js-scroll-target bg-wild-thyme pb-10 lg:pb-20 px-ogs",
            },
            [
              _c("div", { staticClass: "lg:grid lg:grid-cols-12" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-span-10" },
                  [
                    _vm.form
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "max-w-md lg:max-w-4xl mx-auto pt-20 pb-12 lg:pb-20 text-center",
                          },
                          [
                            _c("h3", {
                              staticClass:
                                "text-3xl lg:text-4xl xl:text-5xl mb-4 leading-tight",
                              domProps: {
                                textContent: _vm._s(_vm.form.heading),
                              },
                            }),
                            _vm._v(" "),
                            _c("h4", {
                              staticClass:
                                "text-xl lg:text-3xl mb-10 leading-tight",
                              domProps: {
                                textContent: _vm._s(_vm.form.subheading),
                              },
                            }),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "lg:text-xl",
                              domProps: { innerHTML: _vm._s(_vm.form.text) },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("UnblurYourBackgroundResultsForm", {
                      attrs: { result: _vm.title },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hidden lg:block relative" }, [
      _c(
        "div",
        {
          staticClass:
            "lg:absolute lg:inset-0 flex items-center justify-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "font-semibold lg:transform lg:-rotate-90 whitespace-no-wrap",
            },
            [_vm._v("\n              Colour: Wild Thyme\n            ")]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }