var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "UnblurYourBackgroundQuizWrapper",
    {
      key: _vm.$route.name,
      attrs: {
        theme: _vm.data.theme,
        colour: _vm.data.colour,
        question: _vm.data.question,
      },
    },
    [
      _c("div", { staticClass: "text-left lg:-mb-10" }, [
        _c(
          "label",
          {
            staticClass: "block text-sm lg:text-lg ml-2px mb-1 lg:ml-2 lg:mb-2",
            attrs: { for: "email" },
          },
          [_vm._v("Email address*")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email",
            },
          ],
          staticClass:
            "appearance-none rounded-xl w-full py-4 px-5 text-gray-700 leading-tight lg:text-xl focus:outline-none",
          attrs: {
            id: "email",
            type: "text",
            placeholder: "biancaimogen@gmail.com",
          },
          domProps: { value: _vm.email },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.email = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass:
              "inline-flex items-center text-sm mt-2 ml-2px lg:ml-2 leading-snug relative cursor-pointer",
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.marketing,
                  expression: "marketing",
                },
              ],
              staticClass: "opacity-0 absolute h-0 w-0",
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.marketing)
                  ? _vm._i(_vm.marketing, null) > -1
                  : _vm.marketing,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.marketing,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.marketing = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.marketing = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.marketing = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "w-4 h-4 border mr-2 transition-colors duration-200 rounded-xs",
                class: _vm.marketing ? "bg-black" : "bg-white",
              },
              [
                _c(
                  "svg",
                  {
                    staticClass:
                      "h-full w-full transition-opacity duration-200 p-2px",
                    class: _vm.marketing ? "opacity-100" : "opacity-0",
                    attrs: { viewBox: "0 0 18 15" },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M17.7434 1.66954L6.18368 14.4936L0.257812 7.91954L1.74336 6.58046L6.18368 11.5064L16.2578 0.33046L17.7434 1.66954Z",
                        fill: "white",
                      },
                    }),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "I agree to receive marketing emails from Natural Paint Co"
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "lg:h-6 mt-4" }, [
          _c("div", { staticClass: "email-error text-red hidden" }, [
            _vm._v("\n        Please enter a valid email address\n      "),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }