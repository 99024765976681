<template>
  <ul class="flex flex-col space-y-2 lg:space-y-3 text-left">
    <li v-for="(item, index) in items" :key="index">
      <label
        :for="item.value"
        class="answer-item"
        :class="
          value.value === item.value ? 'border-black' : 'border-transparent'
        "
      >
        <input
          :id="item.value"
          class="answer-item__radio"
          type="radio"
          :name="name"
          :value="value.value"
          :checked="value.value === item.value"
          @change="$emit('handle-change', item)"
        />
        <span class="answer-item__indicator">
          <svg
            class="h-4 transition-opacity duration-200 p-px"
            :class="value.value === item.value ? 'opacity-100' : 'opacity-0'"
            viewBox="0 0 18 15"
          >
            <path
              d="M17.7434 1.66954L6.18368 14.4936L0.257812 7.91954L1.74336 6.58046L6.18368 11.5064L16.2578 0.33046L17.7434 1.66954Z"
              fill="white"
            />
          </svg>
        </span>
        <span v-text="item.text"></span>
      </label>
    </li>
    <li v-show="note" class="text-left text-base pt-4" v-text="note"></li>
  </ul>
</template>

<script>
export default {
  name: "UnblurYourBackgroundQuizRadioGroup",
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    note: {
      type: String,
      default: "",
    },
  },
};
</script>
