import VTooltip from "v-tooltip";
import { isMobile } from "mobile-device-detect";
import vSelect from "vue-select";
import router from "./router";
import { formatVolume, removeEmptyElements } from "./utils";

// App main
const main = async () => {
  // Import our CSS
  await import(/* webpackChunkName: "styles" */ "../css/app.css");

  // Async load the vue module
  const store = await import(/* webpackChunkName: "store" */ "../vue/store");
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ "vue");
  const { mapState } = await import(/* webpackChunkName: "vuex" */ "vuex");
  const { default: VueRouter } = await import(
    /* webpackChunkName: "vue-router" */ "vue-router"
  );

  Vue.use(VueRouter);
  Vue.component("v-select", vSelect);
  Vue.use(VTooltip, { defaultContainer: "#app" });
  Vue.mixin({
    filters: {
      formatVolume,
    },
    methods: {
      nl2br: (str) => str.replace(/(?:\r\n|\r|\n)/g, "<br>"),
      removeEmptyElements,
    },
  });

  router.beforeResolve((to, from, next) => {
    store.default.commit("setQuestion", to.name);
    next();
  });

  // Create our vue instance
  /* eslint-disable no-unused-vars */
  const vm = new Vue({
    el: "#app",
    router,
    store: store.default,
    components: {
      ButtonInfo: () =>
        import(/* webpackChunkName: "ButtonInfo" */ "../vue/ui/ButtonInfo.vue"),
      ColourSelector: () =>
        import(
          /* webpackChunkName: "ColourSelector" */ "../vue/ColourSelector/ColourSelector.vue"
        ),
      PaintCalculator: () =>
        import(
          /* webpackChunkName: "PaintCalculator" */ "../vue/QuantityCalculator/PaintCalculator.vue"
        ),
      SiteCart: () =>
        import(
          /* webpackChunkName: "SiteCart" */ "../vue/SiteCart/SiteCart.vue"
        ),
      CheckoutCart: () =>
        import(
          /* webpackChunkName: "CheckoutCart" */ "../vue/CheckoutCart/CheckoutCart.vue"
        ),
      CheckoutShipping: () =>
        import(
          /* webpackChunkName: "CheckoutShipping" */ "../vue/CheckoutCart/steps/CheckoutShipping.vue"
        ),
      CheckoutPayment: () =>
        import(
          /* webpackChunkName: "CheckoutPayment" */ "../vue/CheckoutCart/steps/CheckoutPayment.vue"
        ),
      SiteCartMenuItem: () =>
        import(
          /* webpackChunkName: "SiteCartMenuItem" */ "../vue/SiteCart/SiteCartMenuItem.vue"
        ),
      TwigCartFieldUpdater: () =>
        import(
          /* webpackChunkName: "TwigCartFieldUpdater" */ "../vue/SiteCart/TwigCartFieldUpdater.vue"
        ),
      SiteFavourites: () =>
        import(
          /* webpackChunkName: "SiteFavourites" */ "../vue/SiteFavourites/SiteFavourites.vue"
        ),
      SiteFavouritesMenuButton: () =>
        import(
          /* webpackChunkName: "SiteFavouritesMenuButton" */ "../vue/SiteFavourites/SiteFavouritesMenuButton.vue"
        ),
      SiteFavouritesAddButton: () =>
        import(
          /* webpackChunkName: "SiteFavouritesAddButton" */ "../vue/SiteFavourites/SiteFavouritesAddButton.vue"
        ),
      SiteMenuMega: () =>
        import(
          /* webpackChunkName: "SiteMenuMega" */ "../vue/SiteMenu/SiteMenuMega.vue"
        ),
      SiteMenuAbout: () =>
        import(
          /* webpackChunkName: "SiteMenuAbout" */ "../vue/SiteMenu/SiteMenuAbout.vue"
        ),
      SiteMenuFlyout: () =>
        import(
          /* webpackChunkName: "SiteMenuFlyout" */ "../vue/SiteMenuFlyout/SiteMenuFlyout.vue"
        ),
      ProductSelector: () =>
        import(
          /* webpackChunkName: "ProductSelector" */ "../vue/ProductSelector.vue"
        ),
      ProductCoating: () =>
        import(
          /* webpackChunkName: "ProductCoating" */ "../vue/Product/ProductCoating.vue"
        ),
      ProductPaint: () =>
        import(
          /* webpackChunkName: "ProductPaint" */ "../vue/Product/ProductPaint.vue"
        ),
      ProductSupplementary: () =>
        import(
          /* webpackChunkName: "ProductSupplementary" */ "../vue/Product/ProductSupplementary.vue"
        ),
      ProductSupplies: () =>
        import(
          /* webpackChunkName: "ProductSupplies" */ "../vue/Product/ProductSupplies.vue"
        ),
      ProductVoucher: () =>
        import(
          /* webpackChunkName: "ProductVoucher" */ "../vue/Product/ProductVoucher.vue"
        ),
      SiteMask: () =>
        import(/* webpackChunkName: "SiteMask" */ "../vue/SiteMask.vue"),
      SiteMessage: () =>
        import(/* webpackChunkName: "SiteMessage" */ "../vue/SiteMessage.vue"),
      FlexibleContentRichAccordion: () =>
        import(
          /* webpackChunkName: "FlexibleContentRichAccordion" */ "../vue/FlexibleContentRich/FlexibleContentRichAccordion.vue"
        ),
      FlexibleContentRichAccordionSimple: () =>
        import(
          /* webpackChunkName: "FlexibleContentRichAccordionSimple" */ "../vue/FlexibleContentRich/FlexibleContentRichAccordionSimple.vue"
        ),
      FlexibleContentRichCarousel: () =>
        import(
          /* webpackChunkName: "FlexibleContentRichCarousel" */ "../vue/FlexibleContentRich/FlexibleContentRichCarousel.vue"
        ),
      FlexibleContentRichFeatures: () =>
        import(
          /* webpackChunkName: "FlexibleContentRichFeatures" */ "../vue/FlexibleContentRich/FlexibleContentRichFeatures.vue"
        ),
      FlexibleContentRichImageGallery: () =>
        import(
          /* webpackChunkName: "FlexibleContentRichImageGallery" */ "../vue/FlexibleContentRich/FlexibleContentRichImageGallery.vue"
        ),
      FlexibleContentRichPartners: () =>
        import(
          /* webpackChunkName: "FlexibleContentRichPartners" */ "../vue/FlexibleContentRich/FlexibleContentRichPartners.vue"
        ),
      FlexibleContentRichPopularColours: () =>
        import(
          /* webpackChunkName: "FlexibleContentRichPopularColours" */ "../vue/FlexibleContentRich/FlexibleContentRichPopularColours.vue"
        ),
      FlexibleContentRichTestimonials: () =>
        import(
          /* webpackChunkName: "FlexibleContentRichTestimonials" */ "../vue/FlexibleContentRich/FlexibleContentRichTestimonials.vue"
        ),
      FlexibleContentRichColourComparison: () =>
        import(
          /* webpackChunkName: "FlexibleContentRichColourComparison" */ "../vue/FlexibleContentRich/FlexibleContentRichColourComparison.vue"
        ),
      FlexibleContentRichColourInspiration: () =>
        import(
          /* webpackChunkName: "FlexibleContentRichColourInspiration" */ "../vue/FlexibleContentRich/FlexibleContentRichColourInspiration.vue"
        ),
      FlexibleContentSimpleAccordion: () =>
        import(
          /* webpackChunkName: "FlexibleContentSimpleAccordion" */ "../vue/FlexibleContentSimple/FlexibleContentSimpleAccordion.vue"
        ),
      ModalOrderConfirmation: () =>
        import(
          /* webpackChunkName: "ModalOrderConfirmation" */ "../vue/Modal/ModalOrderConfirmation.vue"
        ),
      ModalPopup: () =>
        import(
          /* webpackChunkName: "ModalPopup" */ "../vue/Modal/ModalPopup.vue"
        ),
      ColourOverlay: () =>
        import(
          /* webpackChunkName: "ColourOverlay" */ "../vue/ColourOverlay.vue"
        ),
      PressGrid: () =>
        import(
          /* webpackChunkName: "PressGrid" */ "../vue/Press/PressGrid.vue"
        ),
      PressFeaturedCarousel: () =>
        import(
          /* webpackChunkName: "PressFeaturedCarousel" */ "../vue/Press/PressFeaturedCarousel.vue"
        ),
      HubspotForm: () =>
        import(
          /* webpackChunkName: "ColourSelector" */ "../vue/Forms/HubspotForm.vue"
        ),
      ContentRevealButton: () =>
        import(
          /* webpackChunkName: "ContentRevealButton" */ "../vue/ContentRevealButton.vue"
        ),
      UnblurYourBackground: () =>
        import(
          /* webpackChunkName: "UnblurYourBackground" */ "../vue/UnblurYourBackground/UnblurYourBackground.vue"
        ),
    },
    data() {
      return {
        activeOrder: null,
        showCart: false,
        previousScrollTop: 0,
        scrollDirection: "static",
        siteMessageHeight: 36,
        siteMessageIsVisible: true,
      };
    },
    computed: {
      ...mapState({
        siteTheme: (state) => state.ui.siteTheme,
        openedSiteMenu: (state) => state.ui.openedSiteMenu,
        siteMenuIsOpen: (state) => !!state.ui.openedSiteMenu,
        siteMenuFlyoutIsOpen: (state) => state.ui.siteMenuFlyoutIsOpen,
        siteCartIsOpen: (state) => state.ui.siteCartIsOpen,
        siteFavouritesIsOpen: (state) => state.ui.siteFavouritesIsOpen,
        paintCalculatorIsOpen: (state) => state.ui.paintCalculatorIsOpen,
        coatingCalculatorIsOpen: (state) => state.ui.coatingCalculatorIsOpen,
        siteMaskIsVisible: (state) => state.ui.siteMaskIsVisible,
        colourOverlayIsOpen: (state) => state.ui.colourOverlayIsOpen,
        selectorIsOpen: (state) => state.ui.selectorIsOpen,
        quiz: (state) => state.quiz,
      }),
      allLayerStates() {
        return (
          this.siteMenuIsOpen ||
          this.siteMenuFlyoutIsOpen ||
          this.siteCartIsOpen ||
          this.siteFavouritesIsOpen ||
          this.paintCalculatorIsOpen ||
          this.coatingCalculatorIsOpen ||
          this.colourOverlayIsOpen ||
          this.selectorIsOpen
        );
      },
    },
    watch: {
      allLayerStates(open) {
        if (open) {
          document.body.classList.add("overflow-hidden");
        } else {
          document.body.classList.remove("overflow-hidden");
        }
      },
    },

    created() {
      this.$store.dispatch("cart/loadItems");
      this.$store.dispatch("favourites/loadFavourites");
    },

    mounted() {
      window.app.emit("vue-mounted");
      this.setWhProperty();
      window.addEventListener("scroll", this.handleSrcollEvent, {
        passive: true,
      });
      window.addEventListener("resize", this.handleResizeEvent);
    },
    methods: {
      isOpenedSiteMenu(key) {
        return key === this.openedSiteMenu;
      },
      getPaymentMethod(response) {
        return `${response.charges.data[0].payment_method_details.card.brand} ${response.charges.data[0].payment_method_details.card.last4}`;
      },
      setWhProperty() {
        document.documentElement.style.setProperty(
          "--window-height",
          isMobile ? `${window.innerHeight}px` : "100vh"
        );
      },
      getScrollTop() {
        return (
          (document.scrollingElement && document.scrollingElement.scrollTop) ||
          document.body.scrollTop ||
          window.pageYOffset
        );
      },
      getScrollDirection() {
        let direction;
        const scrollTop = this.getScrollTop();
        if (scrollTop > this.previousScrollTop) {
          direction = "down";
        } else {
          direction = "up";
        }
        this.previousScrollTop = scrollTop <= 0 ? 0 : scrollTop;
        return direction;
      },
      handleSrcollEvent() {
        const scrollTop = this.getScrollTop();
        const { siteMessageIsVisible, siteMessageHeight } = this;
        if (scrollTop > siteMessageHeight && siteMessageIsVisible) {
          this.siteMessageIsVisible = false;
        } else if (scrollTop <= siteMessageHeight && !siteMessageIsVisible) {
          this.siteMessageIsVisible = true;
        }
        this.scrollDirection = this.getScrollDirection();
      },
      handleResizeEvent() {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1024 && this.siteMenuIsOpen) {
          // this.$store.commit("closeSiteMenuShop");
          // this.$store.commit("closeSiteMenuAbout");
          this.$store.commit("closeSiteMenu");
        }
      },
      getCsrfTokenName() {
        return document
          .querySelector('meta[name="csrfTokenName"]')
          .getAttribute("content");
      },
      getCsrfTokenValue() {
        return document
          .querySelector('meta[name="csrfToken"]')
          .getAttribute("content");
      },
      toggleOrder(order) {
        if (this.activeOrder === order) {
          this.activeOrder = null;
        } else {
          this.activeOrder = order;
        }
      },
    },
  });
};

// Execute async function
main().then(() => {});
