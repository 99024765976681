<template>
  <div>
    <div class="flex justify-center gap-x-4 lg:gap-x-10 mt-10 lg:mt-24 mx-ogs">
      <button
        v-show="!isQuestionOne()"
        :class="buttonGhostClasses"
        @click="navigatePrev"
      >
        Prev
      </button>
      <button
        :class="buttonClasses"
        :disabled="!canProceed"
        @click="navigateNext"
      >
        {{ nextButtonText() }}
      </button>
    </div>
    <p v-if="error" class="text-red pt-2">
      There was an error with the form, please try again
    </p>
  </div>
</template>

<script>
import axios from "axios";
import data from "./Questions/questions.json";
import { flattenUnblurYourBackgroundAnswers } from "../../js/utils";

export default {
  name: "UnblurYourBackgroundNavigation",
  props: {
    theme: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data,
      error: false,
      submitting: false,
    };
  },
  computed: {
    quiz() {
      return this.$store.state.quiz;
    },
    canProceed() {
      if (this.$route.name === 18) {
        return this.quiz.canProceed && !this.submitting;
      }
      return this.quiz.canProceed;
    },
    buttonClasses() {
      const classes =
        this.theme === "black" ? "btn-pill--strong" : "btn-pill--strong-alt";

      return `btn-pill text-sm md:text-base min-h-10 py-2 px-6 w-56 ${classes}`;
    },
    buttonGhostClasses() {
      return `btn-pill text-sm md:text-base min-h-10 py-2 px-6 w-56 ${
        this.theme === "white" ? "btn-pill--alt" : ""
      }`;
    },
  },
  methods: {
    nextButtonText() {
      if (this.$route.meta.next === "results") {
        const buttonText = this.submitting ? "Submitting..." : "Submit";
        return buttonText;
      }
      return "Next";
    },
    isQuestionOne() {
      return this.$route.name === 1;
    },
    async handleSubmit() {
      const emailMessage = document.getElementsByClassName("email-error")[0];

      if (!this.$store.state.quiz.validEmail) {
        emailMessage.classList.remove("hidden");
      } else {
        if (!emailMessage.classList.contains("hidden")) {
          emailMessage.classList.add("hidden");
        }

        const { questions } = this.quiz;
        const tallyQuestions = [];
        const { email } = this.quiz.details;
        // Get all answers that contribute to the tally
        Object.entries(questions).forEach((item) => {
          if (this.data[item[0]].tally) {
            tallyQuestions.push(item);
          }
        });
        const resultsArray = tallyQuestions.map(([_, value]) => value.value);
        let count = null;
        let result = null;
        count = resultsArray.reduce((acc, value) => {
          if (!acc[value]) {
            acc[value] = 1;
          } else {
            acc[value] += 1;
          }
          return acc;
        }, {});
        result = Object.keys(count).reduce((a, b) =>
          count[a] > count[b] ? a : b
        );
        this.$store.commit("setResult", result);
        this.submitting = true;
        // Check for existing contact.
        const { data: resResult, status } = await axios({
          method: "get",
          url: `https://4lnoky9ks8.execute-api.ap-southeast-2.amazonaws.com/prod/get-contact?email=${email}`,
        });
        if (status !== 200) {
          this.error = true;
          this.submitting = false;
          return;
        }
        // Post quiz results only for new contacts or for existing contacts that do not have existing quiz anwsers.
        if (
          resResult.body.total > 0 &&
          resResult.body.results[0].properties
            .campaign_unblur_your_background_answer_one !== "" &&
          resResult.body.results[0].properties
            .campaign_unblur_your_background_answer_one !== null
        ) {
          this.$store.commit("setShowForm", false);
          this.submitting = false;
          this.$router.push({ name: "results" });
        } else {
          this.$store.commit("setShowForm", true);
          // Flatten this.$store.state.quiz.questions object.
          const answers = Object.entries(
            this.$store.state.quiz.questions
          ).reduce(flattenUnblurYourBackgroundAnswers, {});
          // Upsert contact with quiz results.
          const { status: responseStatus } = await axios.post(
            "https://4lnoky9ks8.execute-api.ap-southeast-2.amazonaws.com/prod/create-contact",
            {
              email,
              set_marketing_contact: this.$store.state.quiz.details.marketing,
              ...answers,
            },
            { headers: { "Content-Type": "application/json" } }
          );
          if (responseStatus !== 200) {
            this.error = true;
            this.submitting = false;
            return;
          }
          this.submitting = false;
          this.$router.push({ name: "results" });
        }
      }
    },
    navigateNext() {
      let name = this.$route.meta.next;

      const { fourteen, fourteenB, fifteen, fifteenB } = this.quiz.questions;

      // Handle optional routes

      // Question 14 - check if answer is E or F and navigate to Q14b if it is
      // If Q14 answer changes and Q14b has been selected update the answer to an empty object

      if (this.$route.name === 14) {
        if (fourteen?.value === "E" || fourteen?.value === "F") {
          name = "14b";
        } else if (fourteenB?.value) {
          this.$store.commit("setQuestionFourteenB", {});
        }
      }

      // Question 15 - check if answer is A and navigate to Q15b if it is
      // If Q15 answer changes and Q15b has been selected update the answer to an empty object
      if (this.$route.name === 15) {
        if (fifteen?.value === "A") {
          name = "15b";
        } else if (fifteenB?.value) {
          this.$store.commit("setQuestionFifteenB", {});
        }
      }

      //  Handle submit
      if (this.$route.meta.next === "results") {
        this.handleSubmit();
      } else {
        this.$router.push({ name }).catch((err) => {});
      }
    },
    navigatePrev() {
      let name = this.$route.meta.prev;
      const { fourteenB, fifteenB } = this.quiz.questions;

      //  Handle optional routes

      // If current route is 15 and Q14b has been selected go back to this step
      if (this.$route.name === 15 && fourteenB?.value) {
        name = "14b";
      }

      // If current route is 16 and Q15b has been selected go back to this step
      if (this.$route.name === 16 && fifteenB?.value) {
        name = "15b";
      }

      this.$router.push({ name }).catch((err) => {});
    },
  },
};
</script>
