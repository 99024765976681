var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative z-10 max-w-lg md:max-w-7xl mx-auto" },
    [
      !_vm.loading && _vm.grid.length
        ? _c(
            "div",
            {
              staticClass:
                "grid md:grid-cols-4 gap-2 lg:gap-6 xl:gap-10 text-center",
            },
            _vm._l(_vm.grid, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "rounded-lg overflow-hidden",
                  class: _vm.widthClass(item.width),
                },
                [
                  item.typeHandle === "text"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "bg-white h-full flex items-center justify-center w-full",
                          class: _vm.textRatioClass(item.width),
                        },
                        [
                          _c("div", {
                            staticClass:
                              "p-10 box-content max-w-lg mx-auto lg:text-md xl:text-xl",
                            domProps: { innerHTML: _vm._s(item.text) },
                          }),
                        ]
                      )
                    : item.typeHandle === "image"
                    ? _c(
                        "div",
                        {
                          staticClass: "overflow-hidden h-full",
                          class: _vm.ratioClass(item.width),
                        },
                        [
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                type: "image/webp",
                                srcSet: item.image[0].webp,
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "w-full h-full object-cover",
                              attrs: {
                                loading: "lazy",
                                src: item.image[0].url,
                                alt: item.image[0].title,
                              },
                            }),
                          ]),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass:
                            "h-full px-6 py-10 lg:p-4 flex items-center justify-center transition-opacity duration-200 hover:opacity-70 w-full",
                          class: [
                            _vm.textClass(item.colour[0].textColour),
                            _vm.colourRatioClass(item.width),
                          ],
                          style: { backgroundColor: item.colour[0].colour },
                          attrs: {
                            href: `/colours?colour=${item.colour[0].slug}`,
                            target: "_blank",
                          },
                        },
                        [
                          _c("div", [
                            _c("div", {
                              staticClass:
                                "text-xxs uppercase mb-1 tracking-wide font-semibold leading-tight",
                              domProps: { textContent: _vm._s(item.heading) },
                            }),
                            _vm._v(" "),
                            _c("h5", {
                              staticClass:
                                "text-3xl md:text-xl lg:text-3xl xl:text-4xl leading-tight",
                              domProps: {
                                textContent: _vm._s(item.colour[0].title),
                              },
                            }),
                          ]),
                        ]
                      ),
                ]
              )
            }),
            0
          )
        : _c(
            "div",
            {
              staticClass:
                "grid md:grid-cols-4 gap-2 lg::gap-6 xl:gap-10 text-center",
            },
            _vm._l(9, function (i) {
              return _c("div", {
                key: i,
                staticClass: "rounded-lg bg-white animate-pulse h-full w-full",
                class: _vm.skeletonClass(i),
              })
            }),
            0
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }