<template>
  <div>
    <button
      class="w-full border-b flex items-center justify-between pb-4 mt-4 text-left focus:outline-none group gap-x-6 lg:text-md xl:text-xl"
      @click="showOptions = !showOptions"
    >
      <span
        v-if="!value.text"
        class="opacity-40 group-hover:opacity-70 transition-opacity duration-200"
        >Select an option</span
      >
      <span v-else>{{ value.text }}</span>
      <span
        class="w-6 h-3 transform transition-transform duration-200"
        :class="{ '-rotate-180': showOptions }"
      >
        <IconChevron />
      </span>
    </button>
    <ul
      v-if="showOptions"
      class="flex flex-col space-y-2 lg:space-y-3 text-left mt-4"
    >
      <li v-for="(item, index) in items" :key="index">
        <label
          :for="item.value"
          class="answer-item"
          :class="
            value.value === item.value ? 'border-black' : 'border-transparent'
          "
        >
          <input
            :id="item.value"
            class="answer-item__radio"
            type="radio"
            :name="name"
            :value="value.value"
            :checked="value.value === item.value"
            @change="handleChange(item)"
          />
          <span v-text="item.text"></span>
        </label>
      </li>
      <li v-show="note" class="text-left text-base pt-4" v-text="note"></li>
    </ul>
  </div>
</template>

<script>
import IconChevron from "../../../templates/_includes/svg/icon-chevron.svg";

export default {
  name: "UnblurYourBackgroundQuizSelect",
  components: {
    IconChevron,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    note: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showOptions: false,
    };
  },
  methods: {
    handleChange(item) {
      this.$emit("handle-change", item);
      this.showOptions = !this.showOptions;
    },
  },
};
</script>
