<template>
  <UnblurYourBackgroundQuizWrapper
    v-if="data"
    :key="$route.name"
    :theme="data.theme"
    :colour="data.colour"
    :question="data.question"
  >
    <component
      :is="questionComponent"
      :key="data.question"
      :value="value"
      :name="questionName"
      :items="data.items"
      :note="data.note"
      @handle-change="handleChange"
    ></component>
  </UnblurYourBackgroundQuizWrapper>
</template>

<script>
import themeMixin from "./themeMixin";
import UnblurYourBackgroundQuizWrapper from "../UnblurYourBackgroundQuizWrapper.vue";
import UnblurYourBackgroundQuizRadioGroup from "../UnblurYourBackgroundQuizRadioGroup.vue";
import UnblurYourBackgroundQuizSelect from "../UnblurYourBackgroundQuizSelect.vue";

export default {
  name: "Question",
  components: {
    UnblurYourBackgroundQuizRadioGroup,
    UnblurYourBackgroundQuizSelect,
    UnblurYourBackgroundQuizWrapper,
  },
  mixins: [themeMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    questionName: {
      type: String,
      required: true,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollTop: null,
    };
  },
  computed: {
    value: {
      get() {
        return this.$store.state.quiz.questions[this.questionName];
      },
    },
    questionComponent() {
      return this.dropdown
        ? "UnblurYourBackgroundQuizSelect"
        : "UnblurYourBackgroundQuizRadioGroup";
    },
  },
  mounted() {
    this.checkCanProceed();
  },
  updated() {
    this.checkCanProceed();
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    handleChange(value) {
      const setterName = `setQuestion${this.capitalizeFirstLetter(
        this.questionName
      )}`;
      this.$store.commit(setterName, value);
      this.checkCanProceed();
    },
    checkCanProceed() {
      const canProceed = !!this.value.text;

      this.$store.commit("setCanProceed", { canProceed });
    },
  },
};
</script>
