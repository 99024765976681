import Vue from "vue";
import VueRouter from "vue-router";

import data from "../vue/UnblurYourBackground/Questions/questions.json";

import Question from "../vue/UnblurYourBackground/Questions/Question.vue";
import Email from "../vue/UnblurYourBackground/Questions/Email.vue";
import Results from "../vue/UnblurYourBackground/UnblurYourBackgroundResults.vue";

Vue.use(VueRouter);

const BASE_PATH = "/unblur-your-background";

const routes = [
  {
    path: `${BASE_PATH}`,
    name: 1,
    meta: { prev: null, next: 2 },
    component: Question,
    props: { questionName: "one", data: data.one },
  },
  {
    path: `${BASE_PATH}/two`,
    name: 2,
    meta: { prev: 1, next: 3 },
    component: Question,
    props: { questionName: "two", data: data.two },
  },
  {
    path: `${BASE_PATH}/three`,
    name: 3,
    meta: { prev: 2, next: 4 },
    component: Question,
    props: { questionName: "three", data: data.three },
  },
  {
    path: `${BASE_PATH}/four`,
    name: 4,
    meta: { prev: 3, next: 5 },
    component: Question,
    props: { questionName: "four", data: data.four },
  },
  {
    path: `${BASE_PATH}/five`,
    name: 5,
    meta: { prev: 4, next: 6 },
    component: Question,
    props: { questionName: "five", data: data.five },
  },
  {
    path: `${BASE_PATH}/six`,
    name: 6,
    meta: { prev: 5, next: 7 },
    component: Question,
    props: { questionName: "six", data: data.six },
  },
  {
    path: `${BASE_PATH}/seven`,
    name: 7,
    meta: { prev: 6, next: 8 },
    component: Question,
    props: {
      questionName: "seven",
      data: data.seven,
    },
  },
  {
    path: `${BASE_PATH}/eight`,
    name: 8,
    meta: { prev: 7, next: 9 },
    component: Question,
    props: { questionName: "eight", data: data.eight },
  },
  {
    path: `${BASE_PATH}/nine`,
    name: 9,
    meta: { prev: 8, next: 10 },
    component: Question,
    props: {
      questionName: "nine",
      data: data.nine,
    },
  },
  {
    path: `${BASE_PATH}/ten`,
    name: 10,
    meta: { prev: 9, next: 11 },
    component: Question,
    props: {
      questionName: "ten",
      data: data.ten,
    },
  },
  {
    path: `${BASE_PATH}/eleven`,
    name: 11,
    meta: { prev: 10, next: 12 },
    component: Question,
    props: { questionName: "eleven", data: data.eleven },
  },
  {
    path: `${BASE_PATH}/twelve`,
    name: 12,
    meta: { prev: 11, next: 13 },
    component: Question,
    props: { questionName: "twelve", data: data.twelve },
  },
  {
    path: `${BASE_PATH}/thirteen`,
    name: 13,
    meta: { prev: 12, next: 14 },
    component: Question,
    props: { questionName: "thirteen", data: data.thirteen },
  },
  {
    path: `${BASE_PATH}/fourteen`,
    name: 14,
    meta: { prev: 13, next: 15 },
    component: Question,
    props: {
      questionName: "fourteen",
      data: data.fourteen,
    },
  },
  {
    path: `${BASE_PATH}/fourteen-b`,
    name: "14b",
    meta: { prev: 14, next: 15 },
    component: Question,
    props: { questionName: "fourteenB", data: data.fourteenB },
  },
  {
    path: `${BASE_PATH}/fifteen`,
    name: 15,
    meta: { prev: 14, next: 16 },
    component: Question,
    props: { questionName: "fifteen", data: data.fifteen },
  },
  {
    path: `${BASE_PATH}/fifteen-b`,
    name: "15b",
    meta: { prev: 15, next: 16 },
    component: Question,
    props: { questionName: "fifteenB", data: data.fifteenB },
  },
  {
    path: `${BASE_PATH}/sixteen`,
    name: 16,
    meta: { prev: 15, next: 17 },
    component: Question,
    props: { questionName: "sixteen", data: data.sixteen },
  },
  {
    path: `${BASE_PATH}/seventeen`,
    name: 17,
    meta: { prev: 16, next: 18 },
    component: Question,
    props: { questionName: "seventeen", data: data.seventeen },
  },
  {
    path: `${BASE_PATH}/eighteen`,
    name: 18,
    meta: { prev: 17, next: "results" },
    component: Email,
  },
  {
    path: `${BASE_PATH}/results`,
    name: "results",
    component: Results,
  },
];

const router = new VueRouter({
  mode: "history",
  routes, // short for `routes: routes`
  /* eslint-disable-next-line */
  scrollBehavior(to, from, savedPosition) {
    let behavior = "smooth";
    let selector = ".js-router-scroll-target";

    if (to.name === "results" || from.name === "results") {
      behavior = "auto";
    }

    // prevents smooth scroll on unblur-your-background question one unless returning from question two
    if (to.name === 1 && from.name !== 2) {
      behavior = "auto";
      selector = "#app";
    }

    return { selector, behavior };
  },
});

export default router;
