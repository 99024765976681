var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass:
        "grid lg:grid-cols-2 gap-y-12 lg:gap-y-6 gap-x-10 max-w-5xl mx-auto",
      on: { submit: _vm.submit },
    },
    [
      _c("div", { staticClass: "lg:col-span-1 space-y-7" }, [
        _c("div", [
          _c(
            "label",
            {
              staticClass:
                "block text-sm lg:text-lg ml-2px mb-1 lg:ml-2 lg:mb-2",
              attrs: { for: "name" },
            },
            [_vm._v("Full Name*")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name",
              },
            ],
            staticClass:
              "appearance-none rounded-xl w-full py-4 px-5 text-gray-700 leading-tight lg:text-xl focus:outline-none border",
            class:
              _vm.formInvalid && _vm.$v.name.$invalid
                ? "border-red"
                : "border-white",
            attrs: { id: "name", type: "text", placeholder: "Bianca Imogen" },
            domProps: { value: _vm.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.name = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "label",
            {
              staticClass:
                "block text-sm lg:text-lg ml-2px mb-1 lg:ml-2 lg:mb-2",
              attrs: { for: "colour" },
            },
            [_vm._v("Favourite Natural Paint Co. colour?")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.colour,
                expression: "colour",
              },
            ],
            staticClass:
              "appearance-none rounded-xl w-full py-4 px-5 text-gray-700 leading-tight lg:text-xl focus:outline-none border border-white",
            attrs: { id: "colour", type: "text", placeholder: "Wild Thyme" },
            domProps: { value: _vm.colour },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.colour = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "lg:col-span-1 flex flex-col" }, [
        _c(
          "label",
          {
            staticClass: "block text-sm lg:text-lg ml-2px mb-1 lg:ml-2 lg:mb-2",
            attrs: { for: "image" },
          },
          [_vm._v("Upload image")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "border border-white rounded-lg h-full flex flex-col justify-between items-center p-3",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex-1 flex flex-col justify-center items-center text-center gap-y-3 py-6 lg:py-0",
              },
              [
                _c("div", { staticClass: "relative mt-6" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "btn-pill btn-pill--strong-alt hover:bg-black hover:border-black hover:text-white inline-flex space-x-2 w-56",
                      attrs: { for: "file" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "w-6 h-6" },
                        [_c("IconAddAlt")],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("Add file")]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "hidden",
                        attrs: {
                          id: "file",
                          type: "file",
                          accept: "image/png, image/jpeg",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleFileUpload($event)
                          },
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  this.file
                    ? _c("div", {
                        staticClass: "text-xs absolute top-full inset-x-0 mt-2",
                        domProps: { textContent: _vm._s(this.file.name) },
                      })
                    : _vm._e(),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-sm pt-3" }, [
              _vm._v("Supports JPG/PNG"),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "lg:col-span-2 text-center lg:pt-12 lg:-mb-10" },
        [
          _c(
            "button",
            {
              staticClass:
                "btn-pill btn-pill--strong text-sm md:text-base min-h-10 py-2 px-6 w-56",
              class: _vm.submitting ? " opacity-50" : "",
              attrs: { type: "submit", disabled: _vm.submitting },
            },
            [_vm._v("\n      Submit\n    ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "h-6 mt-4" }, [
            _vm.successMessage
              ? _c("p", [_vm._v(_vm._s(_vm.successMessage))])
              : _vm._e(),
            _vm._v(" "),
            _vm.errorMessage
              ? _c("p", { staticClass: "text-red" }, [
                  _vm._v(_vm._s(_vm.errorMessage)),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "lg:col-span-2 pl-2px lg:pl-2" }, [
      _c(
        "a",
        {
          staticClass: "link underline text-sm",
          attrs: {
            target: "_blank",
            href: "/unblur-your-background-terms-conditions",
          },
        },
        [_vm._v("Read our Terms & Conditions")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }