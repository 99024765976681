var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data
    ? _c(
        "UnblurYourBackgroundQuizWrapper",
        {
          key: _vm.$route.name,
          attrs: {
            theme: _vm.data.theme,
            colour: _vm.data.colour,
            question: _vm.data.question,
          },
        },
        [
          _c(_vm.questionComponent, {
            key: _vm.data.question,
            tag: "component",
            attrs: {
              value: _vm.value,
              name: _vm.questionName,
              items: _vm.data.items,
              note: _vm.data.note,
            },
            on: { "handle-change": _vm.handleChange },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }