var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quiz-wrapper" }, [
    _c(
      "div",
      { staticClass: "pt-4 pb-8 lg:py-30 js-router-scroll-target" },
      [
        _c("div", { staticClass: "text-xs font-semibold lg:hidden" }, [
          _vm._v("\n      Colour: " + _vm._s(_vm.colour.name) + "\n    "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pt-15 lg:pt-0 px-ogs" }, [
          _c(
            "h3",
            {
              staticClass: "text-4xl lg:text-6xl mb-2 leading-tight uppercase",
            },
            [_vm._v("\n        " + _vm._s(_vm.currentQuestion) + "/18\n      ")]
          ),
          _vm._v(" "),
          _c("h4", {
            staticClass:
              "max-w-sm mx-auto lg:max-w-2xl text-2xl xl:text-3xl xl:max-w-3xl leading-tight mb-8 lg:mb-15",
            domProps: { innerHTML: _vm._s(_vm.question) },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "lg:grid lg:grid-cols-12" }, [
            _c("div", { staticClass: "hidden lg:block relative" }, [
              _c(
                "div",
                {
                  staticClass:
                    "lg:absolute lg:inset-0 flex items-center justify-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-semibold lg:transform lg:-rotate-90 whitespace-no-wrap",
                    },
                    [
                      _vm._v(
                        "\n              Colour: " +
                          _vm._s(_vm.colour.name) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-span-10" }, [
              _c(
                "div",
                { staticClass: "w-full xl:max-w-6xl xl:px-5 lg:mx-auto" },
                [_vm._t("default")],
                2
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("UnblurYourBackgroundNavigation", { attrs: { theme: _vm.theme } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }