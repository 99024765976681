<template>
  <div>
    <div class="absolute top-0 left-0 js-router-scroll-target"></div>
    <div class="bg-paint">
      <div class="px-ogs" :class="{ 'pb-20': !showForm }">
        <div class="max-w-md lg:max-w-4xl mx-auto py-20 text-center">
          <transition name="fade">
            <h1
              class="text-3xl lg:text-4xl xl:text-5xl mb-4 leading-tight"
              v-text="heading"
            ></h1>
          </transition>
          <h2
            class="text-xl lg:text-3xl leading-tight"
            :class="{ 'mb-10': showForm }"
          >
            Unblur your background with these colour options.
          </h2>
          <button
            v-if="showForm"
            class="btn-pill btn-pill--strong text-sm md:text-base min-h-10 py-2 px-6 w-auto"
            @click="handleClick"
          >
            Win a paint prize pack!
          </button>
        </div>
        <UnblurYourBackgroundResultsGrid
          :loading="loading"
          :grid="grid"
        ></UnblurYourBackgroundResultsGrid>
      </div>
      <div
        v-if="showForm"
        class="h-24 lg:h-48 bg-gradient-to-t from-wild-thyme -mt-6 lg:-mt-12 flex items-end justify-center"
      >
        <div class="text-xs font-semibold lg:hidden">Colour: Wild Thyme</div>
      </div>
      <div
        v-if="showForm"
        class="js-scroll-target bg-wild-thyme pb-10 lg:pb-20 px-ogs"
      >
        <div class="lg:grid lg:grid-cols-12">
          <div class="hidden lg:block relative">
            <div
              class="lg:absolute lg:inset-0 flex items-center justify-center"
            >
              <div
                class="font-semibold lg:transform lg:-rotate-90 whitespace-no-wrap"
              >
                Colour: Wild Thyme
              </div>
            </div>
          </div>
          <div class="col-span-10">
            <div
              v-if="form"
              class="max-w-md lg:max-w-4xl mx-auto pt-20 pb-12 lg:pb-20 text-center"
            >
              <h3
                class="text-3xl lg:text-4xl xl:text-5xl mb-4 leading-tight"
                v-text="form.heading"
              ></h3>
              <h4
                class="text-xl lg:text-3xl mb-10 leading-tight"
                v-text="form.subheading"
              ></h4>
              <p class="lg:text-xl" v-html="form.text"></p>
            </div>

            <UnblurYourBackgroundResultsForm
              :result="title"
            ></UnblurYourBackgroundResultsForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import UnblurYourBackgroundResultsForm from "./UnblurYourBackgroundResultsForm.vue";
import UnblurYourBackgroundResultsGrid from "./UnblurYourBackgroundResultsGrid.vue";

export default {
  name: "UnblurYourBackgroundResults",
  components: {
    UnblurYourBackgroundResultsForm,
    UnblurYourBackgroundResultsGrid,
  },
  data() {
    return {
      heading: "You're a ...",
      title: null,
      grid: null,
      loading: true,
      form: null,
    };
  },
  computed: {
    showForm() {
      return this.$store.state.quiz.showForm;
    },
    result() {
      let result;

      switch (this.$store.state.quiz.result) {
        case "A":
          result = "pioneer";
          break;
        case "B":
          result = "analyst";
          break;
        case "C":
          result = "high-achiever";
          break;
        case "D":
          result = "anchor";
          break;
        case "E":
          result = "shaper";
          break;
        case "F":
          result = "director";
          break;
        default:
          result = "pioneer";
          break;
      }
      return result;
    },
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    this.$store.commit("setSiteTheme", ["#EFECE2", "#000000"]);
  },
  methods: {
    getData() {
      axios({
        url: "/api",
        method: "post",
        data: {
          query: `
            query ResultsQuery($slug: [String]) {
              form: entries(typeId: "12") {
                ... on unblurYourBackground_unblurYourBackground_Entry {
                  id
                  heading: headingSecondary
                  subheading: headingTertiary
                  text: textSecondary
                }
              }
              entries(sectionId: 9, slug: $slug) {
                ... on unblurYourBackgroundResults_default_Entry {
                  id
                  contentGrid {
                    ... on contentGrid_text_BlockType {
                      id
                      typeHandle
                      width
                      text
                    }
                    ... on contentGrid_image_BlockType {
                      id
                      typeHandle
                      width
                      image {
                        url(width: 1200)
                        webp: url(width: 1200, format: "webp")
                        title
                      }
                    }
                    ... on contentGrid_colour_BlockType {
                      id
                      typeHandle
                      width
                      heading
                      colour {
                        ... on paintColour_Category {
                          id
                          colour
                          title
                          textColour
                          slug
                        }
                      }
                    }
                  }
                  heading
                  title
                  slug
                }
              }
            }
          `,
          variables: {
            slug: this.result,
          },
        },
      }).then((result) => {
        const entry = result?.data.data.entries[0];
        const form = result?.data.data.form[0];
        this.heading = entry.heading;
        this.title = entry.title;
        this.grid = entry.contentGrid;
        this.loading = false;
        this.form = form;
      });
    },
    handleClick() {
      const target =
        document
          .getElementsByClassName("js-scroll-target")[0]
          .getBoundingClientRect().top +
        window.pageYOffset -
        12;

      window.scrollTo({
        top: target,
        behavior: "smooth",
      });
    },
  },
};
</script>
