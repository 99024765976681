var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex justify-center gap-x-4 lg:gap-x-10 mt-10 lg:mt-24 mx-ogs",
      },
      [
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isQuestionOne(),
                expression: "!isQuestionOne()",
              },
            ],
            class: _vm.buttonGhostClasses,
            on: { click: _vm.navigatePrev },
          },
          [_vm._v("\n      Prev\n    ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: _vm.buttonClasses,
            attrs: { disabled: !_vm.canProceed },
            on: { click: _vm.navigateNext },
          },
          [_vm._v("\n      " + _vm._s(_vm.nextButtonText()) + "\n    ")]
        ),
      ]
    ),
    _vm._v(" "),
    _vm.error
      ? _c("p", { staticClass: "text-red pt-2" }, [
          _vm._v(
            "\n    There was an error with the form, please try again\n  "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }